/**
 * @desc Home View
 * 
 *
 */
define('views/app',[

	'jquery',
	'underscore',
	'backbone',
	'utils',
	'bootstrap',
	'stellar',
	'text!templates/person.html'

],	function($, _, Backbone, Utils, Bootstrap, Stellar, PersonTemplate){
	
	return Backbone.View.extend({
		
		// setup DOM Elements
		el : $('body'),
		$featuredPerson: $('.featured-person'),
		featuredPersonTemplate: _.template(PersonTemplate),
		
		// bind Events
		events: {
			'click .trigger-feature-person' : 'viewFeaturedPerson',
			'submit #form-contact' : 'submitForm'
		},

		/**
		 * initialize()
		 * @desc initialize the view
		 *
		 * @param options
		 */
		initialize: function(options){
			$('.video').addClass('in');
			// stellar
			$.stellar({
				// Set scrolling to be in either one or both directions
				horizontalScrolling: true,
				verticalScrolling: true,

				// Set the global alignment offsets
				horizontalOffset: 0,
				verticalOffset: 0,

				// Refreshes parallax content on window load and resize
				responsive: true,

				// Select which property is used to calculate scroll.
				// Choose 'scroll', 'position', 'margin' or 'transform',
				// or write your own 'scrollProperty' plugin.
				scrollProperty: 'scroll',

				// Select which property is used to position elements.
				// Choose between 'position' or 'transform',
				// or write your own 'positionProperty' plugin.
				positionProperty: 'transform',

				// Enable or disable the two types of parallax
				parallaxBackgrounds: false,
				parallaxElements: true,

				// Hide parallax elements that move outside the viewport
				hideDistantElements: false,

				// Customise how elements are shown and hidden
				hideElement: function($elem) { $elem.hide(); },
				showElement: function($elem) { $elem.show(); }
			});

			// parallax
			$('.movement').stellar();
		},
		
		/**
		 * render()
		 * @desc Render the view
		 * 
		 * 
		 */			
		render: function(){
			
			// No render
			
		},


		viewFeaturedPerson: function( event ){
			
			var _this = this;
			
			event.preventDefault();
			
			var $clicked = $(event.currentTarget);
			
			var person = JSON.parse($clicked.attr('data-person'));

			$('.person-list .person.active').removeClass('active');

			$clicked.addClass('active');
			
			var $content = _this.$featuredPerson.find('.featured-person-content');
			
			this.$featuredPerson.addClass('loading');
			
			setTimeout(function(){
				$content.empty().append(_this.featuredPersonTemplate({
					person: person
				}));
			}, 300);
			setTimeout(function(){
				_this.$featuredPerson.addClass('out');
				setTimeout(function(){
					_this.$featuredPerson.removeClass('loading');
					setTimeout(function(){
						_this.$featuredPerson.removeClass('out');
						$content.find('.person-name').animateCss('fadeIn');
						$content.find('.person-role').animateCss('fadeInDown');
						//setTimeout(function(){
						//	$content.find('.person-name').animateCss('fadeIn');
						//}, 500);
					}, 300);
				}, 500);
			}, 500);
			

			$('html, body').animate({
				scrollTop: _this.$featuredPerson.offset().top - 40
			}, 400);
			
		},


		/**
		 *
		 * submitForm
		 *
		 * @param event
		 */
		submitForm: function( event ){

			event.preventDefault();

			var $form = $(event.currentTarget);
			var $button = $form.find('button[type="submit"]');

			if($button.hasClass('disabled')){
				return;
			}

			var $email = $form.find('input[name="email"]');

			if(!Utils.validateEmail($email.val())){
				Utils.alert({
					type: 'error',
					message: 'This email does not look valid. Please try again.'
				});
				$email.focus();
				return;
			}

			Utils.buttonLoading($button);

			$.post( $form.attr('action'), $form.serialize(), function(response) {

					if(response.status === 'error'){

						Utils.alert({
							type: 'error',
							message: response.message ? response.message : 'Error submitting your form. Please try again.'
						});

					} else {

						Utils.alert({
							type: 'success',
							message: "Success. Your message is on the way. We'll get back to your shortly."
						});

						$form[0].reset();

					}

				})
				.fail(function(response) {
					Utils.alert({
						type: 'error',
						message: response.message ? response.message : 'Error submitting your form. Please try again.'
					});
				})
				.always(function(response) {

					Utils.buttonResetLoading($button);

				});
		},
				
	});
});
