/**
 * @desc Initialize the App
 * 
 *
 */
define('app',[
	'jquery',
	'underscore',
	'backbone',
	'router',
	'utils',
	'tether'
	
],	function($, _, Backbone, Router, Utils, Tether){
	
	var initialize = function(){
		
		// this is hack to get Tether for Bootstrap
		window.Tether = Tether;
		
		Router.initialize();
		
	};

	$.fn.extend({
		animateCss: function (animationName) {
			var animationEnd = 'webkitAnimationEnd mozAnimationEnd MSAnimationEnd oanimationend animationend';
			$(this).addClass('animated ' + animationName).one(animationEnd, function() {
				$(this).removeClass('animated ' + animationName);
			});
		}
	});

	return {

	initialize: initialize

	};
  
});
